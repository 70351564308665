



































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { generatePrivileges } from '@/api/privileges'
import { AppModule, DeviceType } from '@/store/modules/app'
import {errorMsg, successMsg} from "@/utils";

@Component({
  name: 'GeneratePrivilegesDetail'
})

export default class extends Vue {
  @Prop({ required: true }) private show!: boolean
  @Prop({ required: true }) private privilegesToGenerate!: string
  private drawer = false

  get drawerSize() {
    if (AppModule.device === DeviceType.Desktop) {
      return 40
    }
    return 100
  }

  private handleClose() {
    this.$emit('close')
  }

  created() {
  }

  private async saveItem() {
    try {
      const formData = new FormData()
      formData.append("keys",this.privilegesToGenerate)
      await generatePrivileges(formData)
      await successMsg('generatePrivilegesDetail.successSave')
      this.$emit('close', true)
    } catch (err) {
      await errorMsg('api.serverError')
    }
  }

  @Watch('show')
  onShowChanged(val: boolean) {
    this.drawer = val
  }
}
