



















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { savePrivilege } from '@/api/privileges'
import { getRoles } from '@/api/roles'
import { AppModule, DeviceType } from '@/store/modules/app'
import {errorMsg, successMsg} from "@/utils";

@Component({
  name: 'PrivilegeDetail'
})

export default class extends Vue {
  @Prop({ required: true }) private show!: boolean
  @Prop({ required: true }) private privilege!: any
  private drawer = false
  private roles = []

  get drawerSize() {
    if (AppModule.device === DeviceType.Desktop) {
      return 40
    }
    return 100
  }

  private handleClose() {
    this.$emit('close')
  }

  created() {
    getRoles({}).then(({ data }) => {
      this.roles = data.collection
    })
  }

  private async saveItem() {
    try {
      await savePrivilege(this.privilege)
      await successMsg('privilegeList.successSave')
      this.$emit('close', true)
    } catch (err) {
      await errorMsg('api.serverError')
    }
  }

  @Watch('show')
  onShowChanged(val: boolean) {
    this.drawer = val
  }
}
