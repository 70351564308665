import { Role } from '@/models/Role'

export class Privilege {
  id: string;
  name: string;
  roles: Role[];

  constructor(data: any = {}) {
    this.id = data.id || null
    this.name = data.name || null
    this.roles = data.roles || []
  }
}
