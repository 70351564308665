var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-container",staticStyle:{"background-color":"#efeff1"}},[_c('list-toolbar',{attrs:{"title":_vm.$t('privilegeList.title')},on:{"value-changed":_vm.onSearchChanged}},[_c('template',{slot:"actions"},[_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['CREATE_PRIVILEGES']),expression:"['CREATE_PRIVILEGES']"}],attrs:{"icon":"el-icon-view"},on:{"click":_vm.generatePrivileges}}),_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['CREATE_GROUPS']),expression:"['CREATE_GROUPS']"}],attrs:{"icon":"el-icon-plus"},on:{"click":_vm.addNewItem}}),_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['DELETE_GROUPS']),expression:"['DELETE_GROUPS']"}],attrs:{"icon":"el-icon-delete"},on:{"click":_vm.deleteItemsBulk}})],1)],2),_c('div',{staticClass:"container-fluid"},[_c('el-row',{directives:[{name:"loading",rawName:"v-loading.lock",value:(_vm.listLoading),expression:"listLoading",modifiers:{"lock":true}}],staticClass:"bee-main-container",attrs:{"type":"flex"}},[_c('el-col',{attrs:{"span":24}},[_c('el-table',{staticClass:"bee-style-table",attrs:{"data":_vm.list,"border":"","fit":"","highlight-current-row":""},on:{"sort-change":_vm.handleSortChange,"selection-change":_vm.handleSelectionChange}},[_c('el-table-column',{attrs:{"width":"45","type":"selection"}}),_c('el-table-column',{attrs:{"align":"center","prop":"translations.title","label":_vm.$t('table.name'),"sortable":"custom"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.name))])]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"dateUpdated","label":_vm.$t('table.dateUpdated'),"sortable":"custom"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.parseTime(row.date_updated)))])]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"dateCreated","label":_vm.$t('table.dateCreated'),"sortable":"custom"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.parseTime(row.date_created)))])]}}])}),(_vm.hasPermission(['EDIT_GROUPS', 'READ_GROUPS', 'DELETE_GROUPS']))?_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('table.actions'),"width":"130px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['EDIT_GROUPS', 'READ_GROUPS']),expression:"['EDIT_GROUPS', 'READ_GROUPS']"}],attrs:{"type":"primary","icon":"el-icon-edit","size":"mini"},on:{"click":function($event){return _vm.editItem(row)}}}),_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['DELETE_GROUPS']),expression:"['DELETE_GROUPS']"}],attrs:{"type":"danger","icon":"el-icon-delete","size":"mini"},on:{"click":function($event){return _vm.deleteItem(row.id)}}})]}}],null,false,2259011910)}):_vm._e()],1)],1)],1),_c('pagination',{attrs:{"hide-on-single-page":true,"total":_vm.total,"page":_vm.listQuery.query.pagination.page,"limit":_vm.listQuery.query.pagination.page_size},on:{"update:page":function($event){return _vm.$set(_vm.listQuery.query.pagination, "page", $event)},"update:limit":function($event){return _vm.$set(_vm.listQuery.query.pagination, "page_size", $event)},"pagination":_vm.getList}})],1),_c('PrivilegeDetail',{attrs:{"privilege":_vm.privilege,"translations":_vm.translations,"show":_vm.editDrawer},on:{"close":_vm.onCloseDrawer}}),_c('GeneratePrivilegesDetail',{attrs:{"privilegesToGenerate":_vm.privilegesToGenerate,"show":_vm.generatePrivilegesDrawer},on:{"close":_vm.onCloseGenerateDrawer}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }