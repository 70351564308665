

























































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import {
  CollectionRequestMeta,
  createFilter,
  getSortItems, ignoreCaseSearchField,
  prepareListFilters
} from '@/utils/filter'
import Pagination from '@/components/Pagination/index.vue'
import ListToolbar from '@/components/ListToolbar/index.vue'
import PrivilegeDetail from './components/PrivilegeDetail.vue'
import GeneratePrivilegesDetail from './components/GeneratePrivilegesDetail.vue'
import { confirmDialog, debounce, errorMsg, hasPermission, parseTime, successMsg } from '@/utils'
import { deletePrivilege, deletePrivilegeBulk, getPrivilege, getPrivileges } from '@/api/privileges'
import { Privilege } from '@/models/Privilege'

@Component({
  name: 'PrivilegeList',
  components: {
    Pagination,
    ListToolbar,
    PrivilegeDetail,
    GeneratePrivilegesDetail
  }
})

export default class extends Vue {
  private total = 0
  private list = []
  private listLoading = true
  private editDrawer = false
  private generatePrivilegesDrawer = false
  private selectedPrivileges = []
  private privilegesToGenerate = ''
  private listQuery: CollectionRequestMeta = createFilter()
  private privilege: Privilege | null = null
  private translations: any = null
  private search = ''
  private debounceMethod = debounce(this.getList)
  private parseTime = parseTime
  private hasPermission = hasPermission

  created() {
    this.getList()
  }

  private generatePrivileges(){
    this.generatePrivilegesDrawer = true
  }

  private addNewItem() {
    this.privilege = new Privilege()
    this.editDrawer = true
  }

  private async editItem(privilege: Privilege) {
    try {
      const { data } = await getPrivilege({
        id: privilege.id,
        withRoles: true
      })
      this.privilege = data
      this.editDrawer = true
    } catch (err) {}
  }

  private onCloseGenerateDrawer(refreshList: boolean) {
    this.generatePrivilegesDrawer = false
    this.privilegesToGenerate = ''
    refreshList && this.getList()
  }
  private onCloseDrawer(refreshList: boolean) {
    this.editDrawer = false
    this.privilege = null
    this.translations = null
    refreshList && this.getList()
  }

  private async deleteItem(id: string) {
    const [data] = await confirmDialog('actions.apiDelete')
    if (!data) return
    try {
      await deletePrivilege({
        id
      })
      await successMsg('actions.apiDeleteSuccess')
      await this.getList()
    } catch (err) {
      await errorMsg('api.serverError')
    }
  }

  private handleSelectionChange(selected = []) {
    this.selectedPrivileges = selected
  }

  private handleSortChange(params: any = {}) {
    if (this.listQuery?.query?.sort) {
      this.listQuery.query.sort = getSortItems(params)
    }
    this.getList()
  }

  private async deleteItemsBulk() {
    if (!this.selectedPrivileges.length) {
      return errorMsg('actions.selectItems')
    }
    const [data] = await confirmDialog('actions.apiDeleteBulk')
    if (!data) return
    try {
      await deletePrivilegeBulk(this.selectedPrivileges.map((item: any) => {
        return item.id
      }))
      await successMsg('actions.apiDeleteBulkSuccess')
      await this.getList()
    } catch (err) {
      await errorMsg('api.serverError')
    }
  }

  private prepareFilters() {
    prepareListFilters({
      listQuery: this.listQuery,
      withDefaultSort: true,
      search: this.search,
      searchFields: [ignoreCaseSearchField('name')]
    })
  }

  private async getList() {
    this.listLoading = true
    try {
      this.prepareFilters()
      const { data } = await getPrivileges(this.listQuery)
      this.list = data.collection
      this.total = data.pagination.total_items
    } catch (err) {}
    this.listLoading = false
  }

  onSearchChanged(search: string) {
    this.search = search
    if (this.listQuery.query?.pagination) {
      this.listQuery.query.pagination.page = 1
    }
    this.debounceMethod()
  }
}
